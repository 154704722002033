type SnackOptionInit = {
  message: string
  timeout?: number
}

type SnackbarOptions = {
  level: 'info' | 'error' | 'warning' | 'success'
  message: string
  timeout: number
}

export interface Snackbar {
  isVisible: Ref<boolean>
  snackbarProps: SnackbarOptions
  showError: (options: SnackOptionInit) => void
  closeSnackbar: () => void
}

export function useSnackbar(): Snackbar {
  const isVisible = ref(false)
  const snackbarProps: SnackbarOptions = reactive({
    level: 'info',
    message: '',
    timeout: -1,
  })

  const _showSnackbar = (dto: SnackbarOptions) => {
    snackbarProps.level = dto.level
    snackbarProps.message = dto.message
    snackbarProps.timeout = dto.timeout
    isVisible.value = true
  }

  const showError = (dto: SnackOptionInit) => {
    _showSnackbar({
      level: 'error',
      timeout: dto.timeout ?? -1,
      message: dto.message,
    })
  }

  const closeSnackbar = () => {
    isVisible.value = false
  }

  return {
    isVisible,
    snackbarProps,
    showError,
    closeSnackbar,
  }
}

export const snackbarInjectionKey: InjectionKey<Snackbar> = Symbol('snackbar')
