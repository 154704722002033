import { Brand } from '~/models/Brand'
import {
  ChatMessageBrand,
  ChatMessageBrandName,
  ChatMessageBase,
  ChatMessageCompletePayment,
  ChatMessageDownloadReceipt,
  ChatMessageError,
  ChatMessageFixed,
  ChatMessagePaymentQR,
  ChatMessageReceivedQR,
  deserializeChatMessage,
} from '~/models/chatMessage/ChatMessage'
import { LanguageCode } from './language'
import { ChatOrderResponse } from '~/composables/useCartRepository'
import { ReceivedQr } from '~/composables/useChatRepository'

type ChatError = 'brandNotFound' | 'receivedQrError' | 'alreadyReceived'

type ErrorWithTimestamp = {
  type: ChatError
  timestamp: number
}

type ChatState = {
  messages: ChatMessageBase[]
  error: ErrorWithTimestamp | null
  lastUpdatedAt: number
}

// メッセージの保存条件
const MAX_ITEMS = 30
const EXPIRATION_TIME_MS = 72 * 60 * 60 * 1000 // 72時間

let counter = 0
const uniqueId = () => {
  counter++
  return `${Date.now().toString(36)}-${counter.toString(36)}`
}

const brandRepository = useBrandRepository()

const brandNameMessage = (brand: Brand, language: LanguageCode) => {
  return new ChatMessageBrandName(uniqueId(), language, brand.name)
}

const brandMessage = (brand: Brand, language: LanguageCode) => {
  return new ChatMessageBrand(uniqueId(), language, brand.id, brand.logoSrc)
}

const scanMessage = (language: LanguageCode) => {
  return new ChatMessageFixed(uniqueId(), language, 'scan')
}
const readyToBuyMessage = (language: LanguageCode) => {
  return new ChatMessageFixed(uniqueId(), language, 'readyToBuy')
}

const paymentQrMessage = (order: ChatOrderResponse, language: LanguageCode) => {
  return new ChatMessagePaymentQR(
    uniqueId(),
    language,
    order.transactionUuid,
    order.expirationDatetime
  )
}

const completePaymentMessage = (
  draftOrderAlias: string,
  language: LanguageCode
) => {
  return new ChatMessageCompletePayment(uniqueId(), language, draftOrderAlias)
}

const receivedQrErrorMessage = (language: LanguageCode) => {
  return new ChatMessageError(uniqueId(), language, 'paymentNotCompleted')
}

const receivedQrMessage = (receivedQr: ReceivedQr, language: LanguageCode) => {
  return new ChatMessageReceivedQR(
    uniqueId(),
    language,
    receivedQr.transactionUuid,
    receivedQr.processedAt
  )
}

const receiptMessage = (draftOrderAlias: string, language: LanguageCode) => {
  return new ChatMessageDownloadReceipt(uniqueId(), language, draftOrderAlias)
}

export const useChatStore = defineStore('chat', {
  state: (): ChatState => ({
    messages: [],
    error: null,
    lastUpdatedAt: 0,
  }),
  persist: {
    storage: localStorage,
    serializer: {
      serialize: (state: any) => {
        return JSON.stringify({
          messages: state.messages.map((it: ChatMessageBase) => it.serialize()),
          lastUpdated: state.lastUpdatedAt,
        })
      },
      deserialize: (data: string) => {
        const object = JSON.parse(data)

        return {
          // desesrializeできなかったものはflatMapで除外
          messages: object.messages.flatMap((it: any) => {
            const deserialized = deserializeChatMessage(it)
            return deserialized !== null ? [deserialized] : []
          }),
          lastUpdatedAt: object.lastUpdated as number,
        }
      },
    },
  },
  actions: {
    initialize(language: LanguageCode) {
      this.cleanUpMessages()

      if (this.messages.length === 0) {
        this.addFirstMessage(language)
      }
    },
    addFirstMessage(language: LanguageCode) {
      this.addMessage(new ChatMessageFixed(uniqueId(), language, 'hello'))
    },
    addMessage(message: ChatMessageBase) {
      // nullが入ってくると壊れるのでnullチェック
      if (message === null) {
        return
      }
      let newMessages = [...this.messages]
      if (newMessages.length >= MAX_ITEMS) {
        newMessages = newMessages.slice(-MAX_ITEMS + 1)
      }
      newMessages.push(message)
      this.messages = newMessages
      this.lastUpdatedAt = Date.now()
    },
    reset() {
      this.messages = []
    },
    cleanUpMessages() {
      if (Date.now() - this.lastUpdatedAt > EXPIRATION_TIME_MS) {
        this.reset()
      }
    },
    async addBrandMessages(brandId: number, language: LanguageCode) {
      try {
        const { brand } = await brandRepository.getBrand(brandId, language)
        // ブランド用のメッセージを生成（テキスト,カード,scanを促す固定文言）
        this.addMessage(brandNameMessage(brand, language))
        this.addMessage(brandMessage(brand, language))
        this.addMessage(scanMessage(language))
      } catch {
        this.error = {
          type: 'brandNotFound',
          timestamp: Date.now(),
        }
      }
    },
    addChatOrder(chatOrder: ChatOrderResponse, language: LanguageCode) {
      this.addMessage(readyToBuyMessage(language))
      this.addMessage(paymentQrMessage(chatOrder, language))
      this.addMessage(
        completePaymentMessage(chatOrder.draftOrderAlias, language)
      )
    },
    async addReceivedQrMessage(
      draftOrderAlias: string,
      language: LanguageCode
    ) {
      const chatRepository = useChatRepository()
      try {
        const { receivedQr, error } = await chatRepository.getReceivedQr(
          draftOrderAlias
        )
        if (error) {
          if (error.data.message === 'DBORDER_NOT_FOUND') {
            this.addMessage(receivedQrErrorMessage(language))
            // 再度completePaymentMessageを追加する
            this.addMessage(completePaymentMessage(draftOrderAlias, language))
            return
          } else if (error.data.message === 'ALREADY_RECEIVED') {
            this.error = {
              type: 'alreadyReceived',
              timestamp: Date.now(),
            }
            return
          }
        }
        this.addMessage(receivedQrMessage(receivedQr, language))
        this.addMessage(receiptMessage(draftOrderAlias, language))
      } catch (e) {
        this.error = {
          type: 'receivedQrError',
          timestamp: Date.now(),
        }
      }
    },
    isFirstVist() {
      return this.lastUpdatedAt === 0
    },
  },
})
