import { customFetch } from './repository/customFetch'

export type ReceivedQr = {
  transactionUuid: string
  processedAt: string
}

class ChatRepository {
  readonly customFetch = customFetch

  public async getReceivedQr(
    draftOrderAlias: string
  ): Promise<ReceivedQr | any> {
    const { data, error } = await this.customFetch<ReceivedQr>(
      `/catalog/chat/received-qr`,
      {
        params: {
          draftOrderAlias,
        },
      }
    )

    // An error.value is returned as a response
    // because the caller is handling the error
    return {
      receivedQr: data.value,
      error: error.value,
    }
  }
}

export function useChatRepository() {
  return new ChatRepository()
}
