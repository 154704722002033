export const INVALID_BRAND_ID = -1
export const useQrCodeReaderStore = defineStore('qrCodeReader', () => {
  const isOpen = ref(false)
  const detectedBrandId = ref(INVALID_BRAND_ID)

  const openDialog = () => {
    isOpen.value = true
    // ダイアログを開くタイミングで初期化
    detectedBrandId.value = INVALID_BRAND_ID
  }

  const closeDialog = () => {
    isOpen.value = false
  }

  const detectBrandIdFromQrCode = (value: string): boolean => {
    // 正しいURLであればbrandIDを保存してtrueを返す
    const brandId = extractBrandIdFromUrl(value)
    if (brandId !== null) {
      detectedBrandId.value = brandId
      return true
    } else {
      detectedBrandId.value = INVALID_BRAND_ID
      return false
    }
  }

  return {
    isOpen,
    detectedBrandId,
    openDialog,
    closeDialog,
    detectBrandIdFromQrCode,
  }
})
