import { LanguageCode, validateLanguageCode } from '~/stores/language'

const CHAT_MESSAGE_TYPES = [
  'Simple',
  'Fixed',
  'CompletePayment',
  'DownloadReceipt',
  'Error',
  'Brand',
  'BrandName',
  'PaymentQR',
  'ReceivedQR',
] as const
export type ChatMessageType = (typeof CHAT_MESSAGE_TYPES)[number]

export abstract class ChatMessageBase {
  abstract readonly messageType: ChatMessageType

  constructor(
    public readonly id: string,
    public readonly language: LanguageCode
  ) {}

  abstract serialize(): object
  static deserialize(data: any): ChatMessageBase {
    throw new Error('Not implemented')
  }
}

export const AvatarTypeValues = ['chatBot'] as const
export type AvatarType = (typeof AvatarTypeValues)[number]
export const validAvatarType = (value: any): value is AvatarType => {
  return AvatarTypeValues.includes(value)
}

export class ChatMessageSimple implements ChatMessageBase {
  readonly messageType = 'Simple'

  constructor(
    public readonly id: string,
    public readonly language: LanguageCode,
    public readonly title: string | null,
    public readonly text: string,
    public readonly avatorType: AvatarType | null
  ) {}

  serialize(): object {
    return { ...this }
  }

  static deserialize(data: any): ChatMessageSimple {
    if (!data.id || !data.language || !data.text) {
      throw new Error('Invalid data')
    }
    if (!validateLanguageCode(data.language)) {
      throw new Error('Invalid language code')
    }
    if (data.avatorType && !validAvatarType(data.avatorType)) {
      throw new Error('Invalid avatar type')
    }
    return new ChatMessageSimple(
      data.id,
      data.language,
      data.title,
      data.text,
      data.avatorType
    )
  }
}

export const ChatMessageFixedTypeValues = [
  'hello',
  'scan',
  'readyToBuy',
] as const
export type ChatMessageFixedType = (typeof ChatMessageFixedTypeValues)[number]
export const validChatMessageFixedType = (
  value: any
): value is ChatMessageFixedType => {
  return ChatMessageFixedTypeValues.includes(value)
}
export class ChatMessageFixed implements ChatMessageBase {
  readonly messageType = 'Fixed'
  constructor(
    public readonly id: string,
    public readonly language: LanguageCode,
    public readonly type: ChatMessageFixedType
  ) {}

  serialize(): object {
    return { ...this }
  }

  static deserialize(data: any) {
    if (!data.id || !data.language || !data.type) {
      throw new Error('Invalid data')
    }
    if (!validateLanguageCode(data.language)) {
      throw new Error('Invalid language code')
    }
    if (!validChatMessageFixedType(data.type)) {
      throw new Error('Invalid type')
    }
    return new ChatMessageFixed(data.id, data.language, data.type)
  }
}

export class ChatMessageCompletePayment implements ChatMessageBase {
  readonly messageType = 'CompletePayment'

  constructor(
    public readonly id: string,
    public readonly language: LanguageCode,
    public readonly draftOrderAlias: string
  ) {}

  serialize(): object {
    return { ...this }
  }

  static deserialize(data: any) {
    if (!data.id || !data.language || !data.draftOrderAlias) {
      throw new Error('Invalid data')
    }
    if (!validateLanguageCode(data.language)) {
      throw new Error('Invalid language code')
    }
    return new ChatMessageCompletePayment(
      data.id,
      data.language,
      data.draftOrderAlias
    )
  }
}

export class ChatMessageDownloadReceipt implements ChatMessageBase {
  readonly messageType = 'DownloadReceipt'

  constructor(
    public readonly id: string,
    public readonly language: LanguageCode,
    public readonly draftOrderAlias: string
  ) {}

  serialize(): object {
    return { ...this }
  }

  static deserialize(data: any) {
    if (!data.id || !data.language || !data.draftOrderAlias) {
      throw new Error('Invalid data')
    }
    if (!validateLanguageCode(data.language)) {
      throw new Error('Invalid language code')
    }
    return new ChatMessageDownloadReceipt(
      data.id,
      data.language,
      data.draftOrderAlias
    )
  }
}

export class ChatMessageBrand implements ChatMessageBase {
  readonly messageType = 'Brand'

  constructor(
    public readonly id: string,
    public readonly language: LanguageCode,
    public readonly brandId: number,
    public readonly logoSrc: string | null // Brandの定義としては必須だが返ってこない場合があるのでnull許容
  ) {}

  serialize(): object {
    return { ...this }
  }

  static deserialize(data: any) {
    if (!data.id || !data.language || !data.brandId) {
      throw new Error('Invalid data')
    }
    if (!validateLanguageCode(data.language)) {
      throw new Error('Invalid language code')
    }
    return new ChatMessageBrand(
      data.id,
      data.language,
      data.brandId,
      data.logoSrc
    )
  }
}
export class ChatMessageBrandName implements ChatMessageBase {
  readonly messageType = 'BrandName'

  constructor(
    public readonly id: string,
    public readonly language: LanguageCode,
    public readonly brandName: string
  ) {}

  serialize(): object {
    return { ...this }
  }

  static deserialize(data: any) {
    if (!data.id || !data.language || !data.brandName) {
      throw new Error('Invalid data')
    }
    if (!validateLanguageCode(data.language)) {
      throw new Error('Invalid language code')
    }
    return new ChatMessageBrandName(data.id, data.language, data.brandName)
  }
}

export class ChatMessagePaymentQR implements ChatMessageBase {
  readonly messageType = 'PaymentQR'

  constructor(
    public readonly id: string,
    public readonly language: LanguageCode,
    public readonly transactionUuid: string,
    public readonly expirationDatetime: string
  ) {}

  serialize(): object {
    return { ...this }
  }

  static deserialize(data: any) {
    if (
      !data.id ||
      !data.language ||
      !data.transactionUuid ||
      !data.expirationDatetime
    ) {
      throw new Error('Invalid data')
    }
    if (!validateLanguageCode(data.language)) {
      throw new Error('Invalid language code')
    }
    return new ChatMessagePaymentQR(
      data.id,
      data.language,
      data.transactionUuid,
      data.expirationDatetime
    )
  }
}

export class ChatMessageReceivedQR implements ChatMessageBase {
  readonly messageType = 'ReceivedQR'

  private static readonly RECEIVING_DELAY_MINUTES = 10 * 60 * 1000 // 10分

  constructor(
    public readonly id: string,
    public readonly language: LanguageCode,
    public readonly transactionUuid: string,
    public readonly processedAt: string
  ) {}

  serialize(): object {
    return { ...this }
  }

  static deserialize(data: any) {
    if (
      !data.id ||
      !data.language ||
      !data.transactionUuid ||
      !data.processedAt
    ) {
      throw new Error('Invalid data')
    }
    if (!validateLanguageCode(data.language)) {
      throw new Error('Invalid language code')
    }
    return new ChatMessageReceivedQR(
      data.id,
      data.language,
      data.transactionUuid,
      data.processedAt
    )
  }

  receivingDate(): Date {
    const processedDate = new Date(this.processedAt)

    if (isNaN(processedDate.getTime())) {
      throw new TypeError('Invalid date string')
    }

    return new Date(
      processedDate.getTime() + ChatMessageReceivedQR.RECEIVING_DELAY_MINUTES
    )
  }
}

export const ChatMessageErrorType = ['paymentNotCompleted'] as const
export type ChatMessageErrorType = (typeof ChatMessageErrorType)[number]
export const validChatMessageErrorType = (
  value: any
): value is ChatMessageErrorType => {
  return ChatMessageErrorType.includes(value)
}
export class ChatMessageError implements ChatMessageBase {
  readonly messageType = 'Error'

  constructor(
    public readonly id: string,
    public readonly language: LanguageCode,
    public readonly type: ChatMessageErrorType
  ) {}

  serialize(): object {
    return { ...this }
  }

  static deserialize(data: any) {
    if (!data.id || !data.language || !data.type) {
      throw new Error('Invalid data')
    }
    if (!validateLanguageCode(data.language)) {
      throw new Error('Invalid language code')
    }
    if (!validChatMessageErrorType(data.type)) {
      throw new Error('Invalid type')
    }
    return new ChatMessageError(data.id, data.language, data.type)
  }
}

const chatMessageClassMapping = {
  Simple: ChatMessageSimple,
  Fixed: ChatMessageFixed,
  CompletePayment: ChatMessageCompletePayment,
  DownloadReceipt: ChatMessageDownloadReceipt,
  Error: ChatMessageError,
  Brand: ChatMessageBrand,
  BrandName: ChatMessageBrandName,
  PaymentQR: ChatMessagePaymentQR,
  ReceivedQR: ChatMessageReceivedQR,
} as const

export const deserializeChatMessage = (serializedObj: any) => {
  try {
    const ClassConstructor =
      chatMessageClassMapping[serializedObj.messageType as ChatMessageType]
    if (!ClassConstructor) {
      throw new Error(`Unknown class: ${serializedObj.messageType}`)
    }
    if (!ClassConstructor.deserialize) {
      throw new Error('deserialize method is not defined in the class')
    }
    return ClassConstructor.deserialize(serializedObj)
  } catch {
    // TODO: sentry飛ばすなりした方が良いかも
    return null
  }
}
